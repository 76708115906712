<template>
    <div id="app">
        
        <div id="clock">
            <table>
                <tr>
                    <td>{{ halloweenDuration.years() }}</td>
                    <td>{{ halloweenDuration.months() }}</td>
                    <td>{{ halloweenDuration.days() }}</td>
                    <td>{{ halloweenDuration.hours() }}</td>
                    <td>{{ halloweenDuration.minutes() }}</td>
                    <td>{{ halloweenDuration.seconds() }}</td>
                </tr>
                <tr>
                    <td>Years</td>
                    <td>Months</td>
                    <td>Days</td>
                    <td>Hours</td>
                    <td>Minutes</td>
                    <td>Seconds</td>
                </tr>
            </table>
            <p class="text">Until Halloween</p>
            <p class="date">{{ currentDateTime }}</p>
        </div>
        </div>
</template>

<script>
    /*eslint-disable */
    import moment from "moment";
    import CountdownClock from "./components/CountdownClock.vue";

    export default {
        name: 'app',
        components: {
            'Countdown-Clock': CountdownClock
        },
        data() {
            return {
                currentDateTime: null,
                halloweenDuration: null,
            }
        },
        created() {
            this.pollData();                       
        },
        methods: {
            pollData() {
                this.polling = setInterval(() => {
                    this.currentDateTime = this.getCurrentDateTime();
                    this.halloweenDuration = this.nextHalloweenDuration();
                }, 1000)
            },
            getCurrentDateTime: function () {
                var time = moment().format();
                return time;
            },
            nextHalloweenDuration: function () {
                var now = moment().local();
                var currentYearHalloween = moment([now.year(), 10 - 1, 31, 0, 0, 0, 0]).local();
                var currentYearDuration = moment.duration(currentYearHalloween.diff(now));
                if (currentYearDuration.as('milliseconds') < 0) {
                    var nextYearHalloween = moment([now.year() + 1, 10 - 1, 31, 0, 0, 0, 0]).local();
                    var nextYearHalloweenDuration = moment.duration(nextYearHalloween.diff(now));
                    return nextYearHalloweenDuration;
                }           
                return currentYearDuration;                      
            }
        },
        beforeDestroy() {
            clearInterval(this.polling)
        },
    };
</script>

<style>
    @import 'https://fonts.googleapis.com/css?family=Share+Tech+Mono';

    html, body {
        height: 100%;
    }

    body {
        background: #0f3854;
        background: radial-gradient(ellipse at center, #0a2e38 0%, #000000 70%);
        background-size: 100%;
    }

    p {
        margin: 0;
        padding: 0;
    }

    #clock {
        font-family: 'Share Tech Mono', monospace;
        color: #ffffff;
        text-align: center;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        color: #daf6ff;
        text-shadow: 0 0 20px rgba(10, 175, 230, 1), 0 0 20px rgba(10, 175, 230, 0);
        .time

    {
        letter-spacing: 0.05em;
        font-size: 80px;
        padding: 5px 0;
    }

    .date {
        letter-spacing: 0.1em;
        font-size: 24px;
    }

    .text {
        letter-spacing: 0.1em;
        font-size: 12px;
        padding: 20px 0 0;
    }

    }
</style>

