<template>
    <div class="home">
        <div>Hello I am the Countdown Clock :D</div>
    </div>
</template>

<script>
    /*eslint-disable */
    import moment from "moment";

    export default {
        name: 'CountdownClock',
        props: {

        },
        data() {
            return {
                currentDateTime: null,
            }
        },
        created() {
            //this.pollData();
            //setInterval(this.setCurrentDateTime(), 1000);
            //setInterval(function () { console.log(this.App.$data.currentDateTime); }, 1000);
            //while (true) {

            //}
            //this.currentTime();
        },
        watch: {
        },
        methods: {
            pollData() {
                this.polling = setInterval(() => {
                    console.log(this.$refs);
                }, 1000)
            },
            getCurrentDateTime: function () {
                var time = moment().format();
                return time;
            }
        },
        beforeDestroy() {
            clearInterval(this.polling)
        },
    }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>

